export const CUSTOMER_STATUS_TYPE = {
    TIEN_DAI_THAO_DUONG: "Tiền đái tháo đường",
    DAI_THAO_DUONG_TIP_1: "Đái tháo đường típ 1",
    DAI_THAO_DUONG_TIP_2: "Đái tháo đường típ 2",
    DAI_THAO_DUONG_THAI_KI: "Đái tháo đường thai kì",
    TANG_HUYET_AP: "Tăng huyết áp",
    BEO_PHI: "Béo phì",
    KHAC: "Khác",
};

export const DISEASES = {
    TANG_MO_MAU : "Tăng mỡ máu (cholesterol/triglyceride)",
    BENH_TIM_MACH : "Bệnh tim mạch",
    BENH_THAN : "Bệnh thận (suy thân, bệnh thận mạn)",
    BENH_MAT : "Bệnh mắt",
    BENH_TUYEN_GIAP : "Bệnh tuyến giáp, viêm giáp",
    BENH_THAN_KINH_NGOAI_BIEN : "Bệnh thần kinh ngoại biên (tê chân, mất cảm giác bàn chân)",
    BENH_TRAM_CAM : "Rối loạn lo âu, trầm cảm",
    BENH_RANG_MIENG : "Bệnh răng miệng",
    BENH_UNG_THU : "Ung thư",
    BENH_KHAC : "Bệnh khác"
};