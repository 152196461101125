<template>
  <div class="customer-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <b-col>
            <basic-input
              label="Tìm kiếm khách hàng trung tâm"
              placeholder="Nhập tên hoặc số điện thoại"
              name="code"
              class="coach-filter"
              :value.sync="filter.name"
              :debounce="1000"
              @v-modal:update="searchDebounce"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-button
          class="btn btn-success"
          type="button"
          @click.stop="onClickAddCustomer"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
      </template>
    </basic-subheader>

    <b-container fluid class="user-app-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <b-overlay :show="loading">
                <template-table
                  :column="column"
                  :data="customerCenters"
                  :tableAction="false"
                  :selectAction="false"
                  :paging="paging"
                  :searchAction="false"
                  boundary="window"
                >
                  <template v-slot:body="{ item, index }">
                    <td>
                      {{ (paging.page - 1) * paging.pageSize + (index + 1) }}
                    </td>
                    <td>
                      {{
                        ` ${$moment(item.startDate * 1000).format(
                          'DD/MM/YYYY',
                        )}`
                      }}
                    </td>
                    <td>{{ item.fullName }}</td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_copy="false"
                        :show_view="false"
                        :show_edit="false"
                        :show_delete="false"
                        :boundary="item.length > 2 ? 'scrollParent' : 'window'"
                      >
                        <template>
                          <template>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item cursor-pointer"
                              @click="editCustomerCenter(item)"
                            >
                              <a class="navi-link text-primary">
                                <span class="menu-icon svg-icon svg-icon-sm">
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                                  />
                                </span>
                                <span class="navi-text text-primary"
                                  >Xem chi tiết</span
                                >
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item cursor-pointer"
                              @click="deleteCustomer(item)"
                            >
                              <a class="navi-link text-danger">
                                <span class="menu-icon svg-icon svg-icon-sm">
                                  <inline-svg
                                    class="svg-icon"
                                    src="/media/svg/icons/Neolex/Basic/power.svg"
                                  />
                                </span>
                                <span class="navi-text text-danger"
                                  >Xóa dữ liệu</span
                                >
                              </a>
                            </b-dropdown-text>
                          </template>
                        </template>
                      </action-dropdown>
                    </td>
                    <td>{{ item.phoneNumber }}</td>
                    <td>
                      {{ item.yearOfBirth === 0 ? `` : item.yearOfBirth }}
                    </td>
                    <td>{{ item.consultee }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.yearOfDiscovery }}</td>
                    <td>{{ item.hba1c }}</td>
                    <td>
                      {{ item.bloodSugar && item.bloodSugar + ' ' + item.unit }}
                    </td>
                    <td>{{ item.agencyName }}</td>
                    <td>{{ item.sourceName }}</td>
                    <td>{{ item.instructorName }}</td>
                    <td class="text-center">
                      <span
                        style="cursor: pointer"
                        class="badge bg-secondary"
                        @click="fnDoctorOrderView(item)"
                        >{{ item.totalDoctorOrder }}</span
                      >
                    </td>
                    <td>{{ item.customerConcerns }}</td>
                    <td>
                      <b-form-checkbox
                        disabled
                        v-model="item.purchase"
                        name="purchase"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      {{
                        item.bloodGlucoseMeter == 1
                          ? 'Có'
                          : item.bloodGlucoseMeter == 2
                          ? 'Không'
                          : ''
                      }}
                    </td>
                    <td>
                      {{
                        item.monitorBloodSugar == 1
                          ? 'Có'
                          : item.monitorBloodSugar == 2
                          ? 'Không'
                          : ''
                      }}
                    </td>
                    <td>
                      <b-form-checkbox
                        disabled
                        v-model="item.isInPatient"
                        name="isInPatient"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox
                        disabled
                        v-model="item.isOutPatient"
                        name="isOutPatient"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox
                        disabled
                        v-model="item.downloadApp"
                        name="downloadApp"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox
                        disabled
                        v-model="item.zalo"
                        name="zalo"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      {{
                        item.newCustomer == null
                          ? ''
                          : item.newCustomer
                          ? 'Khách mới'
                          : 'Khách cũ'
                      }}
                    </td>
                    <td>
                      {{ formatNextDate(item.nextDate) }}
                    </td>
                  </template>
                </template-table>
              </b-overlay>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <customer-center-add
      @loadData="getCustomerCenters"
      :agencies="agencies"
      :sources="sources"
      :doctorOrders="doctorOrders"
    />
    <customer-center-edit
      @loadData="getCustomerCenters"
      :agencies="agencies"
      :sources="sources"
      :isViewed="true"
      :customerCenter="selectedCustomerCenter"
      :doctorOrders="doctorOrders"
    />

    <doctor-order-view
      :customerCenterDoctorOrders="customerCenterDoctorOrders"
    />
  </div>
</template>

<script>
import CustomerCenterAdd from './CustomerCenterAdd.vue';
import CustomerCenterEdit from './CustomerCenterEdit.vue';
import DoctorOrderView from './DoctorOrderView.vue';
import moment from 'moment';

export default {
  name: 'CustomerCenterList',
  components: { CustomerCenterAdd, CustomerCenterEdit, DoctorOrderView },
  computed: {
    searchParams() {
      return {
        page: this.paging.page,
        size: this.paging.pageSize,
        name: this.filter.name,
      };
    },
  },
  data() {
    return {
      loading: false,
      customerCenters: [],
      selectedCustomerCenter: {
        fullName: '',
        customerConcerns: '',
        fromDoctor: '',
        hba1c: null,
        otherStatus: '',
        phoneNumber: '',
        purchase: false,
        sourceId: '',
        startDate: new Date(),
        status: '',
        yearOfBirth: null,
        agencyId: '',
        id: null,
        doctorOrderIds: null,
        diseases: null,
        otherDiseases: null,
        bloodSugar: null,
        unit: null,
        instructorId: null,
        bloodGlucoseMeter: null,
        monitorBloodSugar: null,
        isInPatient: null,
        reason: null,
        downloadApp: false,
        nextDate: null,
        newCustomer: null,
      },
      customerCenterDoctorOrders: [],
      agencies: [],
      sources: [],
      doctorOrders: [],
      column: [
        {
          key: 'STT',
          label: 'STT',
          sortable: false,
          style: {
            width: '50px',
            'min-width': '50px',
          },
        },
        {
          key: 'startDate',
          label: 'Ngày',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'fullName',
          label: 'Họ và tên',
          sortable: false,
          style: {
            width: '200px',
            'min-width': '200px',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '20px',
          },
        },
        {
          key: 'phoneNumber',
          label: 'Số điện thoại',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'yearOfBirth',
          label: 'Năm sinh',
          sortable: false,
          style: {
            width: '100px',
            'min-width': '100px',
          },
        },
        {
          key: 'consultee',
          label: 'Người được tư vấn',
          sortable: false,
          style: {
            width: '200px',
            'min-width': '200px',
          },
        },
        {
          key: 'status',
          label: 'Tình trạng',
          sortable: false,
          style: {
            width: '250px',
            'min-width': '250px',
          },
        },
        {
          key: 'yearOfDiscovery',
          label: 'Năm phát hiện',
          sortable: false,
          style: {
            width: '250px',
            'min-width': '250px',
          },
        },
        {
          key: 'hba1c',
          label: 'HbA1C',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'bloodSugar',
          label: 'Đường huyết đói & đơn vị',
          sortable: false,
          style: {
            width: '250px',
            'min-width': '250px',
          },
        },
        {
          key: 'agencyId',
          label: 'Trung tâm',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'source',
          label: 'Người chỉ định',
          sortable: false,
          style: {
            width: '200px',
            'min-width': '200px',
          },
        },
        {
          key: 'instructorId',
          label: 'Người hướng dẫn ',
          sortable: false,
          style: {
            width: '200px',
            'min-width': '200px',
          },
        },
        {
          key: 'fromDoctor',
          label: 'Chỉ định tư vấn',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'customerConcerns',
          label: 'Ghi chú',
          sortable: false,
          style: {
            width: '300px',
            'min-width': '300px',
          },
        },
        {
          key: 'purchase',
          label: 'Mua hàng',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'bloodGlucoseMeter',
          label: 'Máy đo đường huyết',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'monitorBloodSugar',
          label: 'Theo dõi đường huyết tại nhà',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'isInPatient',
          label: 'Nội trú',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'isOutPatient',
          label: 'Ngoại trú',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'downloadApp',
          label: 'Đã tải app DiaB',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'zalo',
          label: 'Tham gia zalo',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'newCustomer',
          label: 'Loại khách hàng',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
        {
          key: 'nextDate',
          label: 'Ngày tái khám',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '150px',
          },
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        name: null,
        healthCoach: null,
        code: null,
        status: null,
        orderBy: null,
      },
    };
  },
  async created() {
    await this.getCustomerCenters();
    await this.getSearchDataPrepare();
  },
  watch: {
    'paging.page': async function () {
      await this.getCustomerCenters();
    },
    'paging.pageSize': async function () {
      await this.getCustomerCenters();
    },
  },
  methods: {
    formatNextDate(nextDate) {
      return nextDate ? moment(nextDate * 1000).format('DD/MM/YYYY') : '';
    },
    onClickAddCustomer() {
      this.$bvModal.show('customer-center-add-modal');
    },
    onClickResetFilterButton() {
      document.body.click();
    },
    onClickFilterButton() {
      document.body.click();
    },
    async getCustomerCenters() {
      this.$store.commit('context/setLoading', true);
      try {
        const params = this.searchParams;
        const { items, total } = await this.$api.get('CustomerCenter', {
          params,
        });
        this.customerCenters = items ?? [];
        this.paging.total = total ?? 0;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    getSearchDataPrepare() {
      this.$api
        .get('CustomerCenter/PrepareSearchFormItem')
        .then(({ data }) => {
          const { agencyList, sourceList, doctorOrderList } = data;
          this.agencies = agencyList;
          this.sources = sourceList;
          this.doctorOrders = doctorOrderList;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        });
    },
    deleteCustomer(item) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn xóa dữ liệu này không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `Xóa`,
            value: true,
            visible: true,
            className: `btn btn-inactive`,
            closeModal: true,
          },
        },
      }).then(
        async function (result) {
          if (result) {
            await this.$api.delete(`CustomerCenter/${item.id}`).then(() => {
              this.getCustomerCenters();
              this.$toastr.s({
                title: 'Thành công !',
                msg: 'Xóa khách hàng trung tâm thành công.',
              });
            });
          }
        }.bind(this),
      );
    },
    editCustomerCenter(item) {
      this.selectedCustomerCenter = {
        fullName: item.fullName,
        customerConcerns: item.customerConcerns,
        fromDoctor: item.fromDoctor,
        hba1c: item.hba1c,
        otherStatus: item.otherStatus,
        phoneNumber: item.phoneNumber,
        purchase: item.purchase,
        sourceId: item.sourceId,
        startDate: this.convertTimestampToDate(item.startDate),
        status: item.status,
        yearOfBirth: item.yearOfBirth === 0 ? null : item.yearOfBirth,
        agencyId: item.agencyId,
        id: item.id,
        doctorOrderIds: item.doctorOrderIds,
        downloadApp: item.downloadApp,
        diseases: item.diseases,
        otherDiseases: item.otherDiseases,
        bloodSugar: item.bloodSugar,
        unit: item.unit,
        instructorId: item.instructorId,
        bloodGlucoseMeter: item.bloodGlucoseMeter,
        monitorBloodSugar: item.monitorBloodSugar,
        isInPatient: item.isInPatient,
        reason: item.reason,
        yearOfDiscovery: item.yearOfDiscovery,
        zalo: item.zalo,
        consultee: item.consultee,
        isOutPatient: item.isOutPatient,
        newCustomer: item.newCustomer,
        nextDate:
          item.nextDate != null
            ? this.convertTimestampToDate(item.nextDate)
            : null,
      };
      console.log(item.nextDate);
      console.log('==>', this.selectedCustomerCenter);

      this.$bvModal.show('customer-center-edit-modal');
    },
    fnDoctorOrderView(item) {
      if (item.doctorOrderIds && item.doctorOrderIds.length > 0) {
        const doctorOrderItems = this.doctorOrders.filter((m) => {
          return m.group.name === item.status;
        });
        this.customerCenterDoctorOrders = doctorOrderItems.filter((m) => {
          return item.doctorOrderIds.includes(m.value);
        });
      } else {
        this.customerCenterDoctorOrders = [];
      }
      this.$bvModal.show('doctor-orders-modal');
    },
    searchDebounce(value) {
      this.filter.name = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.getCustomerCenters();
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-list-page {
  .customer-list-page__dropdown-form-filter {
    .dropdown-menu {
      .container {
        width: 850px;
      }
    }
  }

  .customer-list-page__body {
    .customer-list-page__body__tabs {
      a.nav-link {
        border: 0;

        &.active {
          font-weight: 600;
          color: #01645a !important;
          border-bottom: 2px solid #01645a;
        }
      }

      li.nav-item {
        a.nav-link:hover {
          color: #333;
        }
      }
    }
  }

  .customer-list-page__divider {
    border: 1px solid #000000;
    height: 40px;
    transform: rotate(200deg);
  }

  .customer-list-page__block {
    width: 275px;

    .customer-list-page__block__text {
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
