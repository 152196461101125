<template>
  <b-modal
    id="customer-center-edit-modal"
    hide-footer
    :title="
      disabled
        ? `Xem chi tiết khách hàng trung tâm`
        : `Cập nhật khách hàng trung tâm`
    "
    size="xl"
    @hide="resetModal"
  >
    <b-container>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-date-picker
                format="DD/MM/YYYY"
                name="startDate"
                :disabled="disabled"
                label="Ngày"
                placeholder="Nhập ngày"
                v-model="form.startDate"
                v-validate="'required'"
                :required="true"
                data-vv-as="Ngày"
                :state="validateState('startDate')"
                value-type="format"
                :invalidFeedback="errors.first('startDate')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                v-model="form.fullName"
                placeholder="Nhập Họ và tên"
                :disabled="disabled"
                :required="true"
                :value.sync="form.fullName"
                label="Họ và Tên"
                v-validate="'required'"
                name="fullName"
                data-vv-as="Họ và Tên"
                :state="validateState('fullName')"
                :invalidFeedback="errors.first('fullName')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập Số điện thoại"
                v-model="form.phoneNumber"
                :disabled="disabled"
                name="phoneNumber"
                label="Số điện thoại"
                inputType="number"
                v-validate="'min: 10|max: 11'"
                maxlength="11"
                minlength="10"
                data-vv-as="Số điện thoại"
                :state="validateState('phoneNumber')"
                :invalidFeedback="errors.first('phoneNumber')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập Năm sinh"
                v-model="form.yearOfBirth"
                :disabled="disabled"
                name="yearOfBirth"
                data-vv-as="Năm sinh"
                inputType="number"
                label="Năm sinh"
                v-validate="{
                  max: 4,
                  year: form.yearOfBirth,
                }"
                :state="validateState('yearOfBirth')"
                :invalidFeedback="errors.first('yearOfBirth')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="Người được tư vấn"
                name="consultee"
                :options="consulteeOpts"
                :state="validateState('consultee')"
                :invalidFeedback="errors.first('consultee')"
                label="Người được tư vấn"
                data-vv-as="Người được tư vấn"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.consultee"
                :value.sync="form.consultee"
                changeValueByObject
                :disabled="disabled"
                @update:value="selected($event, 'consultee', consulteeOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Chọn tình trạng ---"
                name="status"
                :options="statusTypeOpts"
                :disabled="disabled"
                :solid="false"
                v-validate="'required'"
                :state="validateState('status')"
                :invalidFeedback="errors.first('status')"
                :required="true"
                label="Tình trạng"
                :allowEmpty="false"
                track-by="value"
                v-model="form.status"
                :value.sync="form.status"
                value-label="text"
                changeValueByObject
                data-vv-as="Tình trạng"
                @update:value="selected($event, 'status', statusTypeOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" v-if="isOtherStatus">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập tình trạng khác"
                v-model="form.otherStatus"
                label="Khác"
                :disabled="disabled"
                :required="true"
                v-validate="'required'"
                :state="validateState('otherStatus')"
                :invalidFeedback="errors.first('otherStatus')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Năm phát hiện"
                :disabled="disabled"
                v-model="form.yearOfDiscovery"
                inputType="number"
                label="Năm phát hiện"
                name="yearOfDiscovery"
                data-vv-as="Năm phát hiện"
                :required="false"
                v-validate="'max:4'"
                :state="validateState('yearOfDiscovery')"
                :invalidFeedback="errors.first('yearOfDiscovery')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-date-picker
                format="DD/MM/YYYY"
                name="nextDate"
                label="Ngày tái khám"
                :disabled="disabled"
                placeholder="Nhập ngày"
                v-model="form.nextDate"
                data-vv-as="Ngày"
                value-type="format"
                :invalidFeedback="errors.first('nextDate')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Bệnh kèm theo ---"
                name="diseases"
                :options="diseasesOpts"
                :disabled="disabled"
                :state="validateState('diseases')"
                :invalidFeedback="errors.first('diseases')"
                label="Bệnh kèm theo"
                :allowEmpty="true"
                track-by="value"
                valueLabel="text"
                data-vv-as="Bệnh kèm theo"
                :multiple="true"
                v-model="form.diseases"
                :value.sync="form.diseases"
                changeValueByObject
                @update:value="selected($event, 'diseases', diseasesOpts)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="3"
          v-if="form.diseases && form.diseases.includes('Bệnh khác')"
        >
          <b-row>
            <b-col>
              <basic-input
                placeholder="Nhập bệnh kèm theo"
                v-model="form.otherDiseases"
                label="Khác"
                :required="true"
                v-validate="'required'"
                name="otherDiseases"
                data-vv-as="Bệnh kèm theo"
                :state="validateState('otherDiseases')"
                :disabled="disabled"
                :invalidFeedback="errors.first('otherDiseases')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="HbA1C"
                v-model="form.hbA1C"
                :disabled="disabled"
                inputType="number"
                label="HbA1C"
                v-validate="'max_value:30'"
                :state="validateState('hbA1C')"
                name="hbA1C"
                data-vv-as="HbA1C"
                :invalidFeedback="errors.first('hbA1C')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-input
                placeholder="Đường huyết đói"
                v-model="form.bloodSugar"
                inputType="number"
                label="Đường huyết đói"
                name="bloodSugar"
                data-vv-as="Đường huyết đói"
                :required="false"
                :state="validateState('bloodSugar')"
                :disabled="disabled"
                :invalidFeedback="errors.first('bloodSugar')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Đơn vị ---"
                name="unit"
                :options="unitOpts"
                :state="validateState('unit')"
                :invalidFeedback="errors.first('unit')"
                label="Đơn vị"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                data-vv-as="Đơn vị"
                v-model="form.unit"
                :value.sync="form.unit"
                :disabled="disabled"
                @update:value="selected($event, 'unit', unitOpts)"
                changeValueByObject
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="--- Chọn trung tâm ---"
                name="agencyId"
                :disabled="disabled"
                :options="agencies"
                :state="validateState('source')"
                :invalidFeedback="errors.first('agencyId')"
                label="Trung Tâm"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.agencyId"
                data-vv-as="Trung Tâm"
                :value.sync="form.agencyId"
                changeValueByObject
                @update:value="selected($event, 'agencyId', agencies)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="---Người chỉ định---"
                name="source"
                :options="sources"
                :disabled="disabled"
                v-validate="'required'"
                :state="validateState('source')"
                changeValueByObject
                :invalidFeedback="errors.first('source')"
                :required="true"
                label="Người chỉ định"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.source"
                :value.sync="form.source"
                data-vv-as="Người chỉ định"
                @update:value="selected($event, 'source', sources)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <basic-select
                placeholder="---Người hướng dẫn---"
                name="instructorId"
                :options="sources"
                :state="validateState('instructorId')"
                :invalidFeedback="errors.first('instructorId')"
                label="Người hướng dẫn"
                data-vv-as="Người hướng dẫn"
                :allowEmpty="false"
                track-by="value"
                valueLabel="text"
                v-model="form.instructorId"
                :value.sync="form.instructorId"
                :disabled="disabled"
                changeValueByObject
                @update:value="selected($event, 'instructorId', sources)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="doctorOrderOpts && doctorOrderOpts.length > 0">
          <b-row>
            <b-col>
              <b-form-group label="Chỉ định từ bác sĩ">
                <b-form-checkbox-group
                  :id="`checkbox-group`"
                  :name="`checkbox-group`"
                  v-model="form.doctorOrderIds"
                  :options="doctorOrderOpts"
                  value-field="value"
                  stacked
                  class="ml-10"
                  text-field="text"
                  label="Chỉ định từ bác sĩ"
                  :disabled="disabled"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-row>
            <b-col>
              <basic-radio
                label="Máy đo đường huyết:"
                :labelCols="3"
                inline
                name="bloodGlucoseMeter"
                v-model="form.bloodGlucoseMeter"
                :value.sync="form.bloodGlucoseMeter"
                :options="radioOption"
                :disabled="disabled"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" v-if="form.bloodGlucoseMeter == 2">
          <basic-input
            placeholder="Nhập lý do"
            v-model="form.reason"
            label="Lý do"
            :required="true"
            v-validate="'required'"
            name="reason"
            data-vv-as="Lý do"
            :state="validateState('reason')"
            :invalidFeedback="errors.first('reason')"
            :disabled="disabled"
          />
        </b-col>
        <b-col cols="4">
          <b-row>
            <b-col>
              <basic-radio
                label="Theo dõi đường huyết tại nhà:"
                :labelCols="3"
                inline
                name="monitorBloodSugar"
                v-model="form.monitorBloodSugar"
                :value.sync="form.monitorBloodSugar"
                :options="radioOption"
                :disabled="disabled"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score-isInPatient"
                v-model="form.isInPatient"
                :disabled="disabled"
                name="isInPatient"
              >
                Nội trú
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score-isOutPatient"
                v-model="form.isOutPatient"
                :disabled="disabled"
                name="isOutPatient"
              >
                Ngoại trú
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row>
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-score"
                v-model="form.purchase"
                name="purchase"
                :disabled="disabled"
              >
                Mua hàng
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-row style="margin-top: 20px">
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-download-app"
                v-model="form.downloadApp"
                name="downloadApp"
                :disabled="disabled"
              >
                Tải App
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row style="margin-top: 20px">
            <b-col class="py-0">
              <b-form-checkbox
                id="checkbox-zalo"
                :disabled="disabled"
                v-model="form.zalo"
                name="zalo"
              >
                Tham gia Zalo
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="4">
          <b-row>
            <b-col>
              <basic-radio
                label="Khách mới/cũ"
                :labelCols="3"
                inline
                name="newCustomer"
                v-model="form.newCustomer"
                :value.sync="form.newCustomer"
                :options="options"
              ></basic-radio>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <basic-input
                placeholder="Ghi chú"
                v-model="form.customerConcerns"
                name="customerConcerns"
                data-vv-as="Ghi chú"
                label="Nhập ghi chú"
                :state="validateState('customerConcerns')"
                :disabled="disabled"
                :invalidFeedback="errors.first('customerConcerns')"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          v-if="!disabled"
          class="btn btn-success ml-3"
          @click.stop="handelValidation"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
        <b-button
          v-if="disabled"
          class="btn btn-success ml-3"
          @click.stop="handelEdit"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { CUSTOMER_STATUS_TYPE, DISEASES } from './CustomerCenterEnum';
export default {
  props: {
    agencies: {
      type: Array,
      default: () => [],
    },
    customerCenter: {
      type: Object,
      default: () => {},
    },
    sources: {
      type: Array,
      default: () => [],
    },
    isViewed: {
      type: Boolean,
      default: () => true,
    },
    doctorOrders: {
      type: Array,
      default: () => [],
    },
  },
  name: 'CustomerCenterEdit',
  data() {
    return {
      loading: false,
      form: {
        startDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        fullName: '',
        phoneNumber: null,
        yearOfBirth: null,
        hbA1C: null,
        fromDoctor: '',
        customerConcerns: '',
        purchase: false,
        downloadApp: false,
        status: null,
        source: null,
        otherStatus: null,
        agencyId: null,
        id: null,
        doctorOrderIds: null,
        selectedDoctorOrders: [],
        yearOfDiscovery: null,
        diseases: [],
        otherDiseases: null,
        bloodSugar: null,
        unit: { text: 'mg/dL', value: 'mg/dL' },
        instructorId: null,
        bloodGlucoseMeter: null,
        monitorBloodSugar: null,
        isInPatient: null,
        consultee: null,
        zalo: false,
        isOutPatient: false,
        nextDate: null,
        newCustomer: null,
      },
      disabled: true,
      doctorOrderOpts: [],
      currentStatus: null,
      currentDoctorOrderId: [],
      radioOption: [
        { name: 'Có', value: 1 },
        { name: 'Không', value: 2 },
      ],
      options: [
        { name: 'Mới', value: 1 },
        { name: 'Cũ', value: 2 },
      ],
    };
  },
  computed: {
    isOtherStatus() {
      return this.form.status?.value === 'Khác';
    },
    statusTypeOpts() {
      return [
        {
          text: 'Tiền đái tháo đường',
          value: CUSTOMER_STATUS_TYPE.TIEN_DAI_THAO_DUONG,
        },
        {
          text: 'Đái tháo đường típ 1',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_1,
        },
        {
          text: 'Đái tháo đường típ 2',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_TIP_2,
        },
        {
          text: 'Đái tháo đường thai kì',
          value: CUSTOMER_STATUS_TYPE.DAI_THAO_DUONG_THAI_KI,
        },
        { text: 'Tăng huyết áp', value: CUSTOMER_STATUS_TYPE.TANG_HUYET_AP },
        { text: 'Béo phì', value: CUSTOMER_STATUS_TYPE.BEO_PHI },
        { text: 'Khác', value: CUSTOMER_STATUS_TYPE.KHAC },
      ];
    },
    diseasesOpts() {
      return [
        {
          text: 'Tăng mỡ máu (cholesterol/triglyceride)',
          value: DISEASES.TANG_MO_MAU,
        },
        { text: 'Bệnh tim mạch', value: DISEASES.BENH_TIM_MACH },
        {
          text: 'Bệnh thận (suy thân, bệnh thận mạn)',
          value: DISEASES.BENH_THAN,
        },
        { text: 'Bệnh mắt', value: DISEASES.BENH_MAT },
        { text: 'Bệnh tuyến giáp, viêm giáp', value: DISEASES.BENH_TUYEN_GIAP },
        {
          text: 'Bệnh thần kinh ngoại biên (tê chân, mất cảm giác bàn chân)',
          value: DISEASES.BENH_THAN_KINH_NGOAI_BIEN,
        },
        { text: 'Rối loạn lo âu, trầm cảm', value: DISEASES.BENH_TRAM_CAM },
        { text: 'Bệnh răng miệng', value: DISEASES.BENH_RANG_MIENG },
        { text: 'Ung thư', value: DISEASES.BENH_UNG_THU },
        { text: 'Bệnh khác', value: DISEASES.BENH_KHAC },
      ];
    },
    unitOpts() {
      return [
        { text: 'mg/dL', value: 'mg/dL' },
        { text: 'mmol/L', value: 'mmol/L' },
      ];
    },
    consulteeOpts() {
      return [
        { text: 'Người bệnh', value: 'Người bệnh' },
        { text: 'Người thân', value: 'Người thân' },
        { text: 'Cả 2', value: 'Cả 2' },
      ];
    },
  },
  watch: {
    customerCenter: {
      deep: true,
      handler(val) {
        this.doctorOrderOpts = this.doctorOrders.filter((m) => {
          return m.group.name === val.status;
        });
        console.log(val);

        this.form = {
          fullName: val.fullName,
          agencyId: val.agencyId
            ? this.getSelectedValueDropdown(val.agencyId, this.agencies)
            : this.agencies[0],
          customerConcerns: val.customerConcerns,
          fromDoctor: val.fromDoctor,
          hbA1C: val.hba1c,
          otherStatus: val.otherStatus,
          phoneNumber: val.phoneNumber,
          purchase: val.purchase,
          source: this.getSelectedValueDropdown(val.sourceId, this.sources),
          startDate: val.startDate,
          status: this.getSelectedValueDropdown(
            val.status,
            this.statusTypeOpts,
          ),
          yearOfBirth: val.yearOfBirth,
          id: val.id,
          doctorOrderIds: this.getMultipleSelectedDropdown(
            val.doctorOrderIds,
            this.doctorOrderOpts,
          ),
          downloadApp: val.downloadApp,
          diseases: this.getMultipleSelectedDropdownV2(
            val.diseases,
            this.diseasesOpts,
          ),
          otherDiseases: val.otherDiseases,
          bloodSugar: val.bloodSugar,
          unit: this.getSelectedValueDropdown(val.unit, this.unitOpts),
          instructorId: this.getSelectedValueDropdown(
            val.instructorId,
            this.sources,
          ),
          bloodGlucoseMeter: val.bloodGlucoseMeter,
          monitorBloodSugar: val.monitorBloodSugar,
          isInPatient: val.isInPatient,
          reason: val.reason,
          yearOfDiscovery: val.yearOfDiscovery,
          zalo: val.zalo,
          isOutPatient: val.isOutPatient,
          consultee: this.getSelectedValueDropdown(
            val.consultee,
            this.consulteeOpts,
          ),
          newCustomer:
            val.newCustomer == null ? null : val.newCustomer == true ? 1 : 2,
          nextDate: val.nextDate,
        };
        this.currentStatus = this.form.status;
        this.currentDoctorOrderId = this.form.doctorOrderIds;
        this.disabled = true;
      },
    },
    isViewed: {
      deep: true,
      handler(val) {
        this.disabled = val;
      },
    },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      var payload = {
        fullName: this.form.fullName,
        phoneNumber: this.form.phoneNumber,
        startDate: this.convertDateToTimestamp(this.form.startDate),
        hba1c: this.form.hbA1C,
        fromDoctor: this.form.fromDoctor,
        customerConcerns: this.form.customerConcerns,
        purchase: this.form.purchase,
        status: this.form.status?.value,
        sourceId: this.form.source?.value,
        otherStatus: this.form.otherStatus,
        yearOfBirth: this.form.yearOfBirth,
        agencyId: this.form.agencyId?.value,
        doctorOrderIds: this.form.doctorOrderIds,
        downloadApp: this.form.downloadApp ?? false,
        diseases: this.form.diseases && this.form.diseases.map((m) => m.value),
        otherDiseases: this.form.otherDiseases,
        bloodSugar: this.form.bloodSugar,
        unit: this.form.unit?.value ?? null,
        instructorId: this.form.instructorId?.value,
        bloodGlucoseMeter: this.form.bloodGlucoseMeter,
        reason: this.form.reason,
        monitorBloodSugar: this.form.monitorBloodSugar,
        isInPatient: this.form.isInPatient ?? false,
        yearOfDiscovery: this.form.yearOfDiscovery,
        zalo: this.form.zalo ?? false,
        isOutPatient: this.form.isOutPatient ?? false,
        consultee: this.form.consultee?.value ?? null,
        newCustomer: this.form.newCustomer == 2 ? false : true, // change value to boolean
        nextDate:
          this.form.nextDate != null
            ? this.convertDateToTimestamp(this.form.nextDate)
            : null,
      };
      try {
        await this.$api.put(`CustomerCenter/${this.form.id}`, payload);
        this.handleCancel();
        this.$emit('loadData');

        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật khách hàng trung tâm thành công.',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    selected(value, formProperty, optionsArr) {
      if (formProperty === 'status') {
        if (this.currentStatus.value == value.value) {
          this.form.doctorOrderIds = this.currentDoctorOrderId;
          this.form.selectedDoctorOrders = this.currentDoctorOrderId;
        } else {
          this.form.doctorOrderIds = [];
          this.form.selectedDoctorOrders = [];
        }
        this.doctorOrderOpts = this.doctorOrders.filter((m) => {
          return m.group.name === value.value;
        });
      }
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value === value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },
    resetModal() {
      this.form = {
        startDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        fullName: '',
        phone: null,
        yearOfBirth: null,
        hbA1C: null,
        fromDoctor: '',
        customerConcerns: '',
        purchase: false,
        status: null,
        source: null,
        otherStatus: null,
        agencyId: null,
        id: null,
        yearOfDiscovery: null,
        diseases: [],
        otherDiseases: null,
        bloodSugar: null,
        unit: { text: 'mg/dL', value: 'mg/dL' },
        instructorId: null,
        bloodGlucoseMeter: null,
        monitorBloodSugar: null,
        isInPatient: null,
        newCustomer: null,
        nextDate: null,
      };
    },
    handleCancel() {
      this.disabled = true;
      this.resetModal();
      this.$bvModal.hide('customer-center-edit-modal');
    },
    getSelectedValueDropdown(selectedId, array) {
      let selectedValue = array.find((m) => {
        return m.value === selectedId;
      });
      return selectedValue
        ? {
            text: selectedValue.text,
            value: selectedValue.value,
          }
        : null;
    },
    getMultipleSelectedDropdown(listSelectedId, array) {
      let selectedItems = [];
      if (listSelectedId && listSelectedId.length > 0) {
        let filteredItems = array.filter((m) => {
          return listSelectedId.includes(m.value);
        });
        selectedItems = filteredItems.map((item) => item.value);
      }
      return selectedItems;
    },
    getMultipleSelectedDropdownV2(listSelectedId, array) {
      let selectedItems = [];
      if (listSelectedId && listSelectedId.length > 0) {
        let filteredItems = array.filter((m) => {
          return listSelectedId.includes(m.value);
        });
        selectedItems = filteredItems.map((item) => item);
      }
      return selectedItems;
    },
    handelEdit() {
      this.disabled = false;
    },
  },
};
</script>

<style scoped></style>
